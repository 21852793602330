/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import UserChangePassword from '../komponents/UserChangePassword/UserChangePassword';
import LoginComponent from '../komponents/LoginComponent/LoginComponent';
import useAuth from '../hooks/useAuth';

const CambiaContrasena: FunctionComponent = () => {
  const { state, isAuthenticated } = useAuth();

  return (
    <>
      <Header />
      {isAuthenticated ? <UserChangePassword /> : <LoginComponent />}
      <Footer />
    </>
  );
};

export default CambiaContrasena;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Change Password PuntoTerminal" />
    <title>Cambia contraseña - PuntoTerminal.mx</title>
  </>
);
