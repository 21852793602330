import React from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { Link } from 'gatsby';
import { pad, errorMessage, formDiv } from './UserChangePassword.module.scss';
import useAuth from '../../hooks/useAuth';

const pwSchema = yup.object().shape({
  Password: yup.string().required().min(5),
  PasswordConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref('Password')], 'Passwords do not match'),
});

type LanguageContent = {
  leftHeroHeader: string;
  leftHeroBody: string;
  // form
  login: string;
  loginLink: string;
  personFirstname: string;
  personLastname: string;
  CompanyName: string;
  CompanyNumber: string;
  Email: string;
  Phone: string;
  Password: string;
  ConfirmPW: string;
  termsText: string;
  termsLink: string;
  privacyLink: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  leftHeroHeader: 'Your quotes are ready!',
  leftHeroBody: 'We need a way to contact you. Please create an account.',
  // Form
  login: 'Already registered? Click ',
  loginLink: 'here to login.',
  personFirstname: 'First name',
  personLastname: 'Last name',
  CompanyName: 'Company Name',
  CompanyNumber: 'Company Registration Number',
  Email: 'Email',
  Phone: 'Phone',
  Password: 'Password',
  ConfirmPW: 'Confirm Password',
  termsText: 'By singning up, you agree to our',
  termsLink: ' Terms,',
  privacyLink: ' Data Policy and Cookies.',
};
content.Español = {
  leftHeroHeader: 'Tus ofteras estan listas',
  leftHeroBody: 'Para ver las ofertas, necesitas una cuenta con PuntoTerminal.',
  // Form
  login: 'Tienes una cuenta? Click ',
  loginLink: 'aqui para logear.',
  personFirstname: 'Nombre',
  personLastname: 'Apellidos',
  CompanyName: 'Nombre de la empresa',
  CompanyNumber: 'RFC CURP',
  Email: 'Correo electronico',
  Phone: 'Telefono',
  Password: 'Contraseña',
  ConfirmPW: 'Confirmar Contraseña',
  termsText: 'Con el acto de crear el usario, acepta nuestros ',
  termsLink: ' Términos y condiciones,',
  privacyLink: ' Política y Cookies.',
};

class UserChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Form data
      Password: '',
      errors_Password: false,
      PasswordConfirm: '',
      errors_PasswordConfirm: false,
    };
  }

  choosenLanguage: LanguageContent;

  handleSubmit = () => {
    const pwData = {
      password: this.state.Password,
      passwordConfirm: this.state.PasswordConfirm,
    };
    this.validateSchema(pwSchema, pwData, alert('Form submitted'));
  };

  async validateSchema(schema: any, data: any, nextStep: any) {
    const currentErrors = [];

    const validationResult = await schema
      .validate(data, { abortEarly: false })
      .then(() => {
        // Form is valid, move to next step.
        nextStep();
      })
      .catch((err) => {
        err.inner.forEach((e) => {
          // console.log(e.message, e.path);

          currentErrors.push(e.path);
          const stateName = `errors_${e.path}`;
          this.setState({
            [stateName]: true,
          });
        });
      });

    // Iterate over data and reset errors if no longer an error.
    for (const key in data) {
      if (!currentErrors.includes(key)) {
        const stateName = `errors_${key}`;
        this.setState({
          [stateName]: false,
        });
      }
    }
  }

  render() {
    const { logout, isAuthenticated } = useAuth();

    const handleLogout = (e) => {
      e.preventDefault();
      logout();
    };

    if (this.props.language === 'Español') {
      this.choosenLanguage = content.Español;
    } else {
      this.choosenLanguage = content.English;
    }

    return (
      <>
        <Container className="d-flex justify" id="wrapper">
          <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="list-group list-group-flush">
              <Link to="/">
                <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                  Home
                </Button>
              </Link>

              <Link to="/usario-contacto">
                <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                  Datos de Contacto
                </Button>
              </Link>

              <Link to="/cambia-contrasena">
                <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                  Cambiar contraseña
                </Button>
              </Link>

              <Link to="/">
                <Button
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Link>
            </div>
          </div>
          <div className={formDiv}>
            <div className={pad} />

            <div>
              <h1>Cambiar contraseña</h1>
              <Form>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label> {this.choosenLanguage.Password} </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        name="Password"
                        value={this.state.Password}
                        onChange={this.state.handleChange}
                      />

                      {this.state.errors_Password && (
                        <p className={errorMessage}>
                          Por favor elige una contraseñas de minimo 5
                          characteres.
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        {' '}
                        {this.choosenLanguage.ConfirmPW}{' '}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirmar Contraseña"
                        name="PasswordConfirm"
                        value={this.state.PasswordConfirm}
                        onChange={this.state.handleChange}
                      />

                      {this.state.errors_PasswordConfirm && (
                        <p className={errorMessage}>
                          Por favor asegurase que las contraseñas son iguales.
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Button
                    variant="success"
                    size="lg"
                    onClick={() => this.handleSubmit()}
                  >
                    <div>Submit</div>
                  </Button>
                </Row>
              </Form>
            </div>
            <div className={pad} />
          </div>
        </Container>
      </>
    );
  }
}

export default UserChangePassword;
