import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image, Form, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import useAuth from '../../hooks/useAuth';
import comprarMPOS from '../../../static/images/compra-con-mpos.jpg';
import { content, image, userErrorText } from './LoginComponent.module.scss';
import useFlowUserStore from '../FlowStore/FlowUserStore';

const LoginComponent: FunctionComponent<{
  showFlow: any;
  toggleFlow: any;
  language: string;
}> = (props) => {
  let choosenLanguage: LanguageContent;

  const { state, login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [client_id, setClientId] = useState(0);
  const [client_secret, setClientSecret] = useState('');
  const [grant_type, setGrantType] = useState('');
  const [error, setError] = useState('');
  const [userError, setUserError] = useState('');

  const setEmail = useFlowUserStore((state) => state.setEmail);

  useEffect(() => {
    // Works similar as componentDidMount.
    const clientSecret = process.env.CLIENT_SECRET_API;
    const clientId = process.env.CLIENT_ID;
    setClientId(clientId);
    setClientSecret(clientSecret);
    setGrantType('password');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const credentials = {
      username,
      password,
      client_id,
      client_secret,
      grant_type,
    };
    // console.log(credentials);
    try {
      await login(credentials);
      setEmail(username);
      navigate('/usario');
    } catch (e) {
      console.log('Error occurred during authentication');
      setUserError(
        'Error con login, verifica que tu correo y contraseña es correcto.'
      );

      const {
        response: {
          data: {
            message: [
              {
                messages: [error],
              },
            ],
          },
        },
      } = e;
      const { message: msg } = error;
      setError(msg);
    }
  };

  return (
    <>
      <Container>
        <div className={content}>
          <Row>
            <Col>
              <h1>Login to PuntoTerminal</h1>
              <Form method="post" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Correo electronico</Form.Label>
                  <Form.Control
                    type="email"
                    name="username"
                    placeholder="Correo electronico"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    value={username}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                  {userError.length > 1 && (
                    <p className={userErrorText}>{userError}</p>
                  )}
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
            <Col className={image}>
              <Image
                src={comprarMPOS}
                alt="Compra con mPOS"
                title="Compra con mPOS"
                rounded
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default LoginComponent;
